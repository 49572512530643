// App.js
import React from 'react';
import CollapsibleSection from './CollapsibleSection';
import './App.css';

const App = () => {
  return (
    <div className="App">
      <h1>These Prompts are designed to guide you in interacting with the PM AI for various Project Management tasks</h1>
      <CollapsibleSection title="Make a Decision">
        <p>Act as a Project Manager.</p> 
        <p>My team is facing a decision between two options with different costs and benefits.</p> 
        <p>Option A has an initial cost of $X and recurring monthly costs of $Y for Z months.</p> 
        <p>Option B has a one-time cost of $W.</p> 
        <p>Show me a detailed cost-benefit analysis for each option in a table format and provide a recommendation based on the analysis.</p>
      </CollapsibleSection>
      <CollapsibleSection title="Create a Persuasive Business Case">
        <p>Act as a Business Analyst.</p> 
        <p>We are considering implementing a new initiative that has an upfront cost of $X but is projected to increase revenue by Y% over the next year.</p> 
        <p>Compose a business case to the decision maker, highlighting the projected net profit and ROI.</p>
        <p>Use a persuasive and professional tone and bold any important financial metrics.</p>
      </CollapsibleSection>
      <CollapsibleSection title="Create a Project Charter">
        <p>Act as a Project Manager.</p> 
        <p>We are planning a new project to build a facility of X square meters in [location].</p> 
        <p>The project must comply with all relevant regulations, stay within a budget of $Y, and be completed within Z months.</p> 
        <p>Create a project charter that includes design and construction plans, procurement strategies, quality checks, and compliance measures.</p> 
        <p>List key stakeholders and their roles.</p>
      </CollapsibleSection>
      <CollapsibleSection title="Manage Scope Creep">
        <p>Act as a Project Manager.</p> 
        <p>My project originally included [initial scope]. The client now requests additional features such as [new requirements].</p> 
        <p>These changes will increase the timeline by X months and the budget by $Y.</p> 
        <p>Create a requirements traceability matrix in a table format and suggest how to manage the scope creep and communicate the impact on the project timeline and budget to the client.</p>
      </CollapsibleSection>
      <CollapsibleSection title="Calculate Earned Value Management (EVM)">
        <p>Act as a Financial Analyst.</p> 
        <p>A project has a budget of $X and a timeline of Y months.</p> 
        <p>After Z months, the actual cost (AC) is $W and the project is P% complete.</p> 
        <p>Calculate the Earned Value (EV), Estimate to Complete (ETC), and Variance at Completion (VAC).</p> 
        <p>Present the results in a table format and explain if the project is over or under budget and behind or ahead of schedule.</p>
      </CollapsibleSection>
      <CollapsibleSection title="Create a Power/Interest Grid">
        <p>Act as a Stakeholder Analyst.</p>
        <p>For a project, certain stakeholders have varying levels of power and interest.</p> 
        <p>Create a power/interest grid categorizing stakeholders into “keep satisfied,” “manage closely,” “monitor,” and “keep informed.”</p> 
        <p>Provide a detailed table format for this grid.</p>
      </CollapsibleSection>
      <CollapsibleSection title="Identify the Critical Path">
        <p>Act as a Project Scheduler.</p> 
        <p>A project has several activities with the following dependencies and durations:</p>
        <p>Activity 1: Predecessor = start, duration = X days</p>
        <p>Activity 2: Predecessor = Activity 1, duration = Y days</p>
        <p>Activity 3: Predecessor = Activity 1, duration = Z days</p>
        <p>Activity 4: Predecessor = Activity 2, duration = W days</p>
        <p>Activity 5: Predecessor = Activity 3, 4, duration = V days</p>
        <p>Show the dependencies, early start (ES), early finish (EF), late start (LS), late finish (LF) times, float for each task, and all paths with durations.</p>
        <p>Highlight the critical path with the least float value in bold.</p>
      </CollapsibleSection>
      <CollapsibleSection title="Create a Project Schedule">
        <p>Act as a Project Planner.</p> 
        <p>Using the critical path data from the previous exercise, create a detailed project schedule.</p> 
        <p>Present the schedule in a table format, including start and end dates, duration, and assigned resources for each activity.</p>
      </CollapsibleSection>
      <CollapsibleSection title="Create a Cause-and-Effect Diagram">
        <p>Act as a Project Manager.</p> 
        <p>We are experiencing delays in our project.</p> 
        <p>Identify potential root causes by categorizing them into areas such as equipment, material, personnel, and environmental factors.</p> 
        <p>Create a detailed cause and effect diagram in ASCII format and identify the primary root cause, highlighting it in bold.</p>
      </CollapsibleSection>
      <CollapsibleSection title="Create User Stories">
        <p>Act as a Product Owner.</p> 
        <p>We are developing a new feature for our application that allows users to [feature description].</p> 
        <p>Create an agile user story in two tables:</p> 
        <p>one for the user story using the format “As a [persona], I want [what], so that [desired outcome]”</p> 
        <p>and another for the acceptance criteria using the Given-When-Then formula.</p>
      </CollapsibleSection>
      <CollapsibleSection title="Manage Risk">
        <p>Act as a Risk Manager.</p> 
        <p>For a project similar to a past high-stakes project that experienced significant issues, perform a detailed risk analysis.</p> 
        <p>Include risk identification, assessment, and mitigation strategies in a table format.</p> 
        <p>Highlight the suggested solutions in bold.</p>
      </CollapsibleSection>
    </div>
  );
};

export default App;
