// CollapsibleSection.js
import React, { useState } from 'react';
import styled from 'styled-components';

const Section = styled.div`
  margin-bottom: 10px;
`;

const Header = styled.div`
  cursor: pointer;
  background-color: #4CAF50;
  color: white;
  padding: 10px;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;

  &:hover {
    background-color: #45a049;
  }
`;

const Content = styled.div`
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-top: none;
  padding: 10px;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Icon = styled.span`
  font-size: 24px;
  line-height: 1;
`;

const CollapsibleSection = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Section>
      <Header onClick={toggleOpen}>
        <span>{title}</span>
        <Icon>{isOpen ? '-' : '+'}</Icon>
      </Header>
      {isOpen && <Content>{children}</Content>}
    </Section>
  );
};

export default CollapsibleSection;

